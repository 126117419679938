import React from "react";
// import PaymentForm from "./payment";

export default function Summary({ formData, setFormData, lang, book }: { formData: any, setFormData: any, lang: string, book:any }) {
    return (
        <>
            <p className="font-bold">{lang === 'pt' ? 'Detalhes da reserva:' : 'Book details'}</p>
            {/* Parking */}
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    Parking:
                </label>
                <div className="w-2/3">
                    {formData.parking[lang]}
                </div>
            </div>
            {/* Delivery Options */}
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Entrega em:" : "Delivery at:"}
                </label>
                <div className="w-2/3">
                    {formData.delivery[lang]}
                </div>
            </div>
            {/* Extra Services */}
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Serviços extra:" : "Extra services:"}
                </label>
                <div className="w-2/3">
                    {
                        formData.extraServices.length !== 0 ? formData.extraServices.map((ele:any, index:number) => {
                        return (
                                <p key={index} className=''>
                                    {ele[lang]}{index !== formData.extraServices.length - 1 && ','}
                                </p>
                            )
                        }) :
                        <p className='w-full text-center'>Nenhum resultado</p>
                    }
                </div>
            </div>
            {/* Check-in Date */}
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Data de entrada:" : "Check-in Date:"}
                </label>
                <div className="w-2/3">
                    {formData.checkinDate || "Campo vazio"}
                    {" | "}
                    {formData.inputTimeHours?.toString().padStart(2, '0') || "HH"}:{formData.inputTimeMinutes?.toString().padStart(2, '0') || "MM"}
                </div>
            </div>
            {/* Departure Date */}
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Data de saída:" : "Departure Date:"}
                </label>
                <div className="w-2/3">
                    {formData.checkoutDate || "Campo vazio"}
                    {" | "}
                    {formData.outputTimeHours?.toString().padStart(2, '0') || "HH"}:{formData.outputTimeMinutes?.toString().padStart(2, '0') || "MM"}
                </div>
            </div>
            {/* License Plate */}
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Matrícula:" : "License Plate:"}
                </label>
                <div className="w-2/3">
                    {formData.licensePlate.toUpperCase() || "Campo vazio"}
                </div>
            </div>
            {/* Booking Price */}
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Preço da reserva:" : "Booking Price:"}
                </label>
                <div className="w-2/3">
                    {formData.bookingPrice + '€' || "Campo vazio"}
                </div>
            </div>
            <div className="w-full h-[1px] bg-[grey]"></div>
            <p className="font-bold">{lang === 'pt' ? 'Detalhes pessoais:' : 'Personal details'}</p>
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Carro:" : "Car:"}
                </label>
                <p>{`${formData.brand} ${formData.model}, ${formData.color}`}</p>
            </div>
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Nome:" : "Name:"}
                </label>
                <p>{formData.name}</p>
            </div>
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Apelido:" : "Last name:"}
                </label>
                <p>{formData.lastName}</p>
            </div>
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    {lang === "pt" ? "Telemóvel:" : "Phone number:"}
                </label>
                <p>{formData.phoneNumber}</p>
            </div>
            <div className="flex items-center gap-4">
                <label className="w-1/3">
                    E-mail:
                </label>
                <p>{formData.email}</p>
            </div>
            {
                formData.taxName &&
                <>
                    <div className="flex items-center gap-4">
                        <label className="w-1/3">
                            {lang === "pt" ? "Nome para fatura:" : "Name for invoice:"}
                        </label>
                        <p>{formData.taxName}</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <label className="w-1/3">
                            {lang === "pt" ? "Número de contribuinte:" : "Taxpayer number:"}
                        </label>
                        <p>{formData.taxNumber}</p>
                    </div>
                </>
            }
            <div className="flex items-center gap-4">
                <label className="w-1/3 text-balance">
                    {lang === 'pt' ? 'Aceito os Termos e Condições' : 'Accept the Terms & Conditions'}
                </label>
                <div onClick={() => setFormData({ ...formData, terms: !formData.terms })} className={`rounded-[5px] border-2 border-black w-5 aspect-square cursor-pointer ${formData.terms && 'bg-[var(--primary)]'}`}></div>
            </div>
            {/* <div className="w-full h-[1px] bg-[grey]"></div> */}
            {/* <p className="font-bold">{lang === 'pt' ? 'Detalhes de pagamento:' : 'Payment details:'}</p> */}
            {/* <PaymentForm setFormData={setFormData} formData={formData} book={book} /> */}
        </>
    );
}
