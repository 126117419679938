import React, { useEffect, useState } from 'react';

import Logo from '../content/images/logo2.png';

export default function About({ texts }: any) {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='px-[8vw] min-h-screen py-[5rem] font-poppins'>
            <div className='flex milecem:flex-row flex-col-reverse milecem:items-center justify-between gap-[2rem] mb-4 text-left'>
                <h1 className='text-left w-full sete:w-[40rem] text-[1.2rem] quatro:text-[1.5rem] cinco:text-[2rem] sete:text-[2.5rem] font-bold text-balance uppercase'>{texts[lang]?.title}</h1>
                <img src={Logo} alt="logo" className='w-[15rem] seis:w-[20rem]' />
            </div>
            <div className='font-poppinsM'>
                No coração de Lisboa, nasceu o Skypark, um projeto moldado pelo sonho visionário de seu fundador, André Oliveira. Especializado em serviços de estacionamento coberto e descoberto no aeroporto da capital portuguesa, o Skypark vai além, oferecendo um serviço exclusivo de valet park.  
                <br /><br />
                O Skypark orgulha-se em disponibilizar assistência 24 horas, garantindo a entrega e recolha de viaturas a qualquer momento. Aqui, a segurança é levada a sério, combinando recursos digitais de ponta com uma presença humana dedicada, assegurando tranquilidade aos nossos clientes.  Além do estacionamento, o Skypark oferece uma gama completa de serviços para a sua conveniência. 
                <br /><br />
                As nossas instalações incluem serviços de manutenção, assegurando que seu veículo receba o cuidado necessário enquanto estiver em nosso estacionamento. Para os adeptos da mobilidade elétrica, disponibilizamos serviços de carregamento de viaturas elétricas, contribuindo para um futuro mais sustentável.  O Skypark vai além das expectativas, oferecendo serviços de Inspeção Periódica Obrigatória (IPO), garantindo que seu veículo esteja em conformidade com os regulamentos em vigor. Este é apenas mais um passo que damos para garantir a sua comodidade.  
                <br /><br />
                Tornando-se parte integrante do Grupo Multipark SA, o Skypark faz parte de uma família de marcas conceituadas. Entre elas, destacam-se a Airpar - Aeroportos de Portugal, Redpark - Estacionamento Aeroporto Lisboa e Multibags - Serviço de Recolha e Guarda de Bagagens.  No Skypark, não apenas estacionamos veículos; cuidamos deles, garantindo a tranquilidade dos nossos clientes. Seja para uma estadia de curto ou longo prazo, oferecemos estacionamento low-cost com a qualidade e segurança que você merece. Conte conosco para tornar a sua experiência de estacionamento no aeroporto de Lisboa impecável e conveniente.
            </div>
        </div>
    );
}

