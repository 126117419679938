import React from 'react';
import { Link } from 'react-router-dom';

export default function ArticleCard({ele}: {ele:any}) {

    const background = ele?.draft ? 'bg-red-500' : 'bg-green-500';
    const isAdmin = window.location.pathname.includes('admin');

    function getLink() {
        if (isAdmin) {
            return `/editArticle/${ele?.id}`;
        } else {
            return `/article/${ele?.id}`;
        }
    }

    return (
        <Link to={getLink()} className='flex flex-col w-full oito:w-[18rem] h-[18rem] bg-white rounded-[25px] place-self-center shadow-lg overflow-hidden relative' >
            <img src={ele?.image} alt="imagem do artigo" className='h-[7rem] object-cover w-full brightness-50 z-[0]' />
            {
                isAdmin &&
                <div className={`absolute right-4 top-4 w-4 h-4 rounded-full ${background}`}></div>
            }
            <div className='flex flex-col justify-between flex-1 p-6'>
                <div className='relative flex flex-col gap-4'>
                    <p className='font-poppins'>{ele?.title}</p>
                </div>
                <p>Publicado no dia: {ele?.publishDate}</p>
            </div>
        </Link>
    );
}

