import React, { useEffect, useState } from 'react';

import PhoneIcon from '../../../content/icons/phone.png';
import EmailIcon from '../../../content/icons/email.png';
import LocationIcon from '../../../content/icons/location.png';
import FooterImg from '../../../content/images/footer-img.jpg';

export default function Footer() {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='relative min-h-screen bg-white px-[8vw] py-[3rem]'>
            <h2 className='text-[2rem] font-bold'>{lang === 'pt' ? 'Contacte-nos' : 'Contact Us'}</h2>
            <div className='flex gap-[10rem] mt-[5rem]'>
                <div className='flex flex-col gap-8'>
                    <div className='flex items-center gap-8'>
                        <div className='w-[10rem] quatro:w-[12rem]'>
                            <p className='text-[1.5rem]'>{lang === 'pt' ? 'Chamada' : 'Call'}</p>
                            <p className='font-bold'>+351 962406952</p>
                        </div>
                        <div className='rounded-full w-[4rem] h-[4rem]'>
                            <img src={PhoneIcon} alt="icon" className='w-full' />
                        </div>
                    </div>
                    <div className='flex items-center gap-8'>
                        <div className='w-[10rem] quatro:w-[12rem]'>
                            <p className='text-[1.5rem]'>Email</p>
                            <a href="mailto:reservas@skypark.pt" className='font-bold hover:underline'>reservas@skypark.pt</a>
                        </div>
                        <div className='rounded-full w-[4rem] h-[4rem]'>
                            <img src={EmailIcon} alt="icon" className='w-full' />
                        </div>
                    </div>
                    <div className='flex items-center gap-8'>
                        <div className='w-[10rem] quatro:w-[12rem]'>
                            <p className='text-[1.5rem]'>{lang === 'pt' ? 'Localização' : 'Household'}</p>
                            <p className='font-bold'>
                                R. B 45, Quinta do
                                Carmo - 2685-129
                                SACAVÉM
                            </p>
                        </div>
                        <div className='rounded-full w-[4rem] h-[4rem]'>
                            <img src={LocationIcon} alt="icon" className='w-full' />
                        </div>
                    </div>
                    <button className='border-[transparent] border-4 bg-[var(--primary)] rounded-[10px] w-full py-4 font-bold text-white hover:bg-white hover:text-black hover:border-[var(--primary)] transition-all duration-300' onClick={() => window.location.href="/book"}>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
                    {/* <div className='flex items-center justify-between w-full'>
                        <p className='text-[2rem]'>Social</p>
                        <div className='flex justify-end gap-2'>
                            <div className='rounded-full bg-[var(--primary)] cursor-pointer w-[3rem] h-[3rem]'>
                                <img src={InstaIcon} alt="insta" className='w-full' />
                            </div>
                            <div className='rounded-full bg-[var(--primary)] cursor-pointer w-[3rem] h-[3rem]'>
                                <img src={PhoneIcon} alt="phone" className='w-full invert' />
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='items-center justify-center hidden w-full oito:flex'>
                    <img src={FooterImg} alt="footer1" className='w-[85%]' />
                </div>
            </div>
            <div className='w-full flex flex-col justify-center items-center gap-8 mt-[5rem]'>
                <a href="/terms" className='w-full text-[1.5rem] text-center hover:underline'>{lang === "pt" ? "Termos & Condições" : "Terms & Conditions"}</a>
                <div className='w-full px-[10vw]'>
                    <div className='bg-[var(--primary)] w-full h-1'></div>
                </div>
                <p className="w-full mt-3 text-center">
					Powered by{" "}
					<a href="https://visiond.pt" className="font-bold hover:underline">
						Vision D
					</a>
				</p>
                <p className='w-full text-center'>© All rights reserved. SkyPark Ltd 2023</p>
            </div>
        </div>
    );
}

