import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ArticleCard from '../components/articleCard';
import { signOut } from 'firebase/auth';

export default function Admin() {

    const navigate = useNavigate();
    const [articles, setArticles] = useState<any>([]);
    const [services, setServices] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        async function fetchData() {
            try {
                let colRef = collection(db, 'skypark/blog/articles');
                let docsSnap = await getDocs(colRef);
                let data: any = [];
                docsSnap.forEach(doc => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                setArticles(data);

                // Fetch services prices
                const docRef = doc(db, 'skypark', 'prices');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let data: any = [];
                    for (const key in docSnap.data()) {
                        data.push({ service: key, price: docSnap.data()[key] });
                    }
                    setServices(data);
                }
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        setIsLoading(true);
        fetchData();
        setIsLoading(false);
    }, []);

    function handleChange(index: number, value: string) {
        setServices((prev: any) => {
            let newArray = [...prev];
            newArray[index].price = value;
            return newArray;
        });
    }

    async function updatePrices() {
        try {
            const docRef = doc(db, 'skypark', 'prices');
            await setDoc(docRef, services.reduce((acc: any, ele: any) => {
                acc[ele.service] = ele.price;
                return acc;
            }, {}));
            alert('Preços atualizados com sucesso!');
        } catch (error) {
            console.error("Error updating document: ", error);
            alert('Erro ao atualizar preços');
        }
    }

    function getService(service: string) {
        switch (service) {
            case 'wash': return 'Lavagem';
            case 'uncoveredPark': return 'P. Descoberto';
            case 'coveredPark': return 'P. Coberto';
            case 'eletric': return 'Carregamento';
            case 'carHealth': return 'Inspeção';
            case 'condition': return 'Condição';
            case 'baseValue': return 'Valor Base';
            default: return '';
        }
    }

    async function logout() {
        await signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error("Error signing out: ", error);
        });
    }

    return (
        <div className='w-full p-8 min-h-screen bg-[#f0f0f0] space-y-10'>
            <div className='flex items-center justify-between gap-4'>
                <h1 className='font-poppins text-[1.2rem] cinco:text-[1.5rem]'>Painel de Administração</h1>
                <div>
                    <button className='px-4 py-2 bg-blue-700 text-white rounded-[25px] justify-center items-center flex place-self-center' onClick={logout}>
                        Logout
                    </button>
                </div>
            </div>
            <div className='space-y-4'>
                <h2 className='text-[1.2rem]'>Artigos</h2>
                {
                    isLoading ? <div className='flex items-center justify-center w-full min-h-screen'>
                        <CircularProgress color="inherit" />
                    </div>
                        :
                        <div className='grid oito:grid-cols-[repeat(auto-fill,18rem)] gap-8'>
                            {
                                articles && articles.map((ele: any, index: number) => {
                                    return (
                                        <ArticleCard key={index} ele={ele} />
                                    )
                                })
                            }
                            <button onClick={() => navigate("/createArticle")}>
                                <div className='w-full oito:w-[18rem] h-[18rem] font-bold bg-white brightness-90 hover:brightness-75 rounded-[25px] justify-center items-center flex place-self-center'>
                                    + criar
                                </div>
                            </button>
                        </div>
                }
            </div>
            <div className='space-y-4'>
                <h2 className='text-[1.2rem]'>Preços</h2>
                <ul className='space-y-4 grid sete:grid-cols-[repeat(auto-fill,18rem)]'>
                    {
                        services && services.length !== 0 ? services.sort((a: any, b: any) => getService(a.service).localeCompare(getService(b.service))).map((ele: any, index: number) => {
                            return (
                                <li key={index} className='flex items-center w-full sete:w-auto'>
                                    <p className='w-full font-bold capitalize sete:w-40 '>{getService(ele.service)}</p>
                                    <input
                                        type="text"
                                        placeholder='Preço'
                                        value={ele.price}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        className='w-full px-2 py-2 rounded-full sete:w-20'
                                    />
                                </li>
                            )
                        }) :
                            <p className='w-full text-center'>Nenhum resultado</p>
                    }
                </ul>

                <button onClick={updatePrices}>
                    <div className='w-40 h-10 bg-green-500 text-white rounded-[25px] justify-center items-center flex place-self-center'>
                        Atualizar Preços
                    </div>
                </button>
            </div>
        </div>
    );
}

