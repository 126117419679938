import React, { useEffect } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";
import Logo from "../../../content/images/logo.png";

const PDFGenerator = ({ formData, setDocRef, lang }: { formData: any, setDocRef: any, lang: string }) => {
   void lang;

   function getCurrentDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();

      return `${dd}/${mm}/${yyyy}`;
   }

   useEffect(() => {
      const input = document.getElementById('pdfContent');
      if (input) {
         html2canvas(input as HTMLElement, { scale: 2 }).then(async (canvas) => {
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;

            const doc = new jsPDF('p', 'mm');
            let position = 0;

            doc.addImage(canvas.toDataURL('image/jpeg', 0.5), 'JPEG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
               position = heightLeft - imgHeight;
               doc.addPage();
               doc.addImage(canvas.toDataURL('image/jpeg', 0.5), 'JPEG', 0, position, imgWidth, imgHeight);
               heightLeft -= pageHeight;
            }
            // doc.save('book.pdf')
            setDocRef(doc.output('datauristring'));
         });
      } else {
         console.error("Element not found");
      }
   }, [setDocRef]);

   return (
      <div className='text-[1rem] pb-10 flex flex-col items-center min-h-screen'>
         <div className='text-center flex flex-col w-[20rem]'>
            <p className="text-balance text-center mt-[5rem]">{lang === 'pt' ? 'Aguarde alguns segundos enquanto concluimos a sua reserva...' : 'Wait a few seconds while we confirm your booking...'}</p>
         </div>
         <div id='pdfContent' className='absolute w-[65rem] px-[8rem] top-[-200vh] left-[-200vw]'>
            <div className='flex items-center justify-center w-full'>
               <img src={Logo} alt="logo" className='w-[30rem]' />
            </div>
            <p className="mt-3 mb-5 text-center uppercase">{formData.city} {getCurrentDate()}</p>
            <div className='flex justify-between w-full '>
               <div className=''>
                  <p className='mb-8 font-bold'>{lang === 'pt' ? 'ID Reserva' : 'Booking ID'}: <span className="font-bold">{formData.idClient}</span></p>
                  <p className='font-bold'>{lang === 'pt' ? 'Matrícula' : 'License Plate'}: <br /> <span className="text-[2rem] font-bold uppercase">{formData.licensePlate}</span></p>
               </div>
               <div className=''>
                  <p className='mb-8 font-bold'>PVP: <span className="font-bold">{formData.bookingPrice} €</span></p>
                  <div className='font-bold'>{lang === 'pt' ? 'Serviços Extra' : 'Extra Services'}: <br /> <span className="font-medium">
                     {
                        formData.extraServices.length !== 0 && formData.extraServices.map((ele: any, index: number) => {
                           return (
                              <p key={index} className=''>
                                 {ele[lang]}{index !== formData.extraServices.length - 1 && ','}
                              </p>
                           )
                        })
                     }</span></div>
               </div>
            </div>

            <div className="flex gap-24 my-10  text-[1.2rem]">
               <p className='font-bold'>{lang === 'pt' ? 'Marca' : 'Brand'}: <span className="font-medium">{formData.brand}</span></p>
               <p className='font-bold'>{lang === 'pt' ? 'Modelo' : 'Model'}: <span className="font-medium">{formData.model}</span></p>
               <p className='font-bold'>{lang === 'pt' ? 'Cor' : 'Color'}: <span className="font-medium">{formData.color}</span></p>
            </div>

            <h2 className='my-5 text-[var(--primary)] font-bold uppercase'>{lang === 'pt' ? 'Partida' : 'Departure'}</h2>

            <div className="flex mb-4 ">
               <div className="flex flex-col mt-2 w-[25%]">
                  <span className="font-bold uppercase">{lang === 'pt' ? 'Data' : 'Date'}</span>
                  <p>{formData.checkinDate}</p>
               </div>

               <div className="flex flex-col mt-2 w-[25%]">
                  <span className="font-bold uppercase">{lang === 'pt' ? 'Hora' : 'Hour'}</span>
                  <p>{formData.inputTimeHours}:{formData.inputTimeMinutes}</p>
               </div>

               <div className="flex flex-col mt-2 w-[25%]">
                  <span className="font-bold uppercase">{lang === 'pt' ? 'Tipo de parque' : 'Parking type'}</span>
                  <p>{formData.parking[lang]}</p>
               </div>

               <div className="flex flex-col mt-2 w-[25%]">
                  <span className="font-bold uppercase">{lang === 'pt' ? 'LOCAL DE ENTREGA' : 'Delivery Point'}</span>
                  <p>{formData.delivery[lang]}</p>
               </div>
            </div>

            <h2 className='my-5 text-[var(--primary)] font-bold uppercase'>{lang === 'pt' ? 'Regresso' : 'Return'}</h2>

            <div className="flex ">
               <div className="flex flex-col mt-2 w-[25%]">
                  <span className="font-bold uppercase">{lang === 'pt' ? 'Data' : 'Date'}</span>
                  <p>{formData.checkoutDate}</p>
               </div>

               <div className="flex flex-col mt-2 w-[25%]">
                  <span className="font-bold uppercase">{lang === 'pt' ? 'Hora' : 'Hour'}</span>
                  <p>{formData.outputTimeHours}:{formData.outputTimeMinutes}</p>
               </div>

               <div className="flex flex-col w-1/2 mt-2">
                  <span className="font-bold uppercase">{lang === 'pt' ? 'Nº VOO / CIDADE DE REGRESSO' : 'Flight number / Return city'}</span>
                  <p>{formData.returnFlight}</p>
               </div>
            </div>
            {
               formData.taxName !== '' &&
               <div className="flex gap-32 my-4 mt-10">
                  <p className='font-bold text-[var(--primary)] uppercase'>{lang === 'pt' ? 'NOME PARA FATURA' : 'Invoice name'}: <br /> <span className="font-medium text-black">{formData.taxName}</span></p>
                  <p className='font-bold text-[var(--primary)] uppercase'>{lang === 'pt' ? 'NIF' : 'Tax number'}: <br /> <span className="font-medium text-black">{formData.taxNumber}</span></p>
               </div>
            }
            {
               formData.remarks !== '' &&
               <div className="mt-10 w-[60%] flex mb-4">
                  <p className='text-[var(--primary)] font-bold uppercase'>OBS: &ensp; <span className='font-medium text-black'>{formData.remarks}</span></p>
               </div>
            }
            <div className="flex justify-between mb-5">
               <div className='flex items-end gap-8 '>
                  <div>
                     <p className='w-[17rem]  font-bold py-4 border-t-2 border-black flex justify-center uppercase'>{lang === 'pt' ? 'ASSINATURA PARTIDA' : 'Departure signature'}</p>
                  </div>
                  <div>
                     <p className='w-[17rem]  font-bold py-4 border-t-2 border-black flex justify-center uppercase'>{lang === 'pt' ? 'ASSINATURA REGRESSO' : 'Return signature'}</p>
                  </div>
               </div>
               <div className='h-[10rem] flex flex-col justify-end'>
                  <p className='mb-4 font-bold text-center uppercase'>ID: {formData.idClient}</p>
                  <div className='w-[7rem]' id='qrcode'>
                     <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`https://admin.multigroup.pt/recolhas/${formData.idClient}`}
                        viewBox={`0 0 256 256`}
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default PDFGenerator;