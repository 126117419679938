import React from 'react';

export default function NotFound() {
    return (
        <div className='flex justify-center items-center flex-col text-[var(--primary)] h-screen'>
            <p onClick={() => window.history.back()} className='absolute left-8 top-8 text-black text-[1.2rem] cursor-pointer'>← &ensp; Go back</p>
            <h1 className='text-[3rem] font-bold'>404 Error</h1>
            <p className='text-[1.2rem]'>Page not found</p>
        </div>
    );
}

