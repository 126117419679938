import React, { useEffect, useState } from 'react';

import Logo from '../content/images/logo2.png';

export default function Terms({ texts }: any) {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='px-[8vw] min-h-screen py-[5rem] font-poppins'>
            <div className='flex milecem:flex-row flex-col-reverse milecem:items-center justify-between gap-[2rem] mb-4 text-left'>
                {lang === 'pt' ? (
                    <h1 className='text-left w-full text-[1.2rem] quatro:text-[1.5rem] cinco:text-[2rem] sete:text-[2.5rem] font-bold'>TERMOS E CONDIÇÕES <br /> GERAIS DE FUNCIONAMENTO</h1>
                ) : (
                    <h1 className='text-left w-full text-[1.2rem] quatro:text-[1.5rem] cinco:text-[2rem] sete:text-[2.5rem] font-bold'>GENERAL TERMS & CONDITIONS <br /> OF SKY PARK OPERATION</h1>
                )}
                <img src={Logo} alt="logo" className='w-[15rem] seis:w-[20rem]' />
            </div>
            <div className='whitespace-pre-wrap font-poppinsM'>
                <p><strong>1. DEFINIÇÕES</strong><br /><br />
                    Neste documento, os termos abaixo terão os seguintes significados:</p>

                <p><strong>Empresa:</strong> Denomina-se SkyPark, uma marca ou denominação comercial sob a qual opera a entidade jurídica responsável pelo parque de estacionamento.<br /><br />
                    <strong>Parque:</strong> Refere-se às instalações do SkyPark, incluindo áreas cobertas e descobertas, protegidas por sistemas de segurança, com monitoramento 24 horas por dia, todos os dias do ano, e com acesso controlado, localizadas dentro de um raio de 10 quilômetros do Aeroporto de Lisboa ou em localidades adjacentes.<br /><br />
                    <strong>Cliente:</strong> Qualquer pessoa física ou jurídica que celebre um contrato com a Empresa para utilização do Parque sob os termos aqui estabelecidos.<br /><br />
                    <strong>Veículo:</strong> Todo e qualquer automóvel que o Cliente tenha o direito legal de utilizar, excluindo-se bens não integrados fixamente ao veículo.<br /><br />
                    <strong>Aeroporto:</strong> Aeroporto Internacional Humberto Delgado, em Lisboa, referência para a localização do SkyPark.</p>
                <br />
                <p><strong>2. OBJETO DO CONTRATO</strong><br /><br />
                    O presente contrato visa regulamentar a utilização das instalações do SkyPark pelo Cliente, conforme os períodos contratados e segundo as condições estabelecidas neste documento.</p>
                <br />
                <p><strong>3. DURAÇÃO DO CONTRATO</strong><br /><br />
                    O contrato vigorará pelo período acordado entre as partes, podendo ser prorrogado com aviso prévio de pelo menos 24 horas.<br /><br />
                    A desistência antecipada por parte do Cliente resultará em uma taxa conforme descrito, salvo comunicação adequada dentro do prazo estipulado.<br /><br />
                    Cancelamentos devem ser notificados com pelo menos 24 horas de antecedência, sob pena de penalização de 50% do valor da reserva.</p>
                <br />
                <p><strong>4. CONDIÇÕES</strong><br /><br />
                    A Empresa oferece ao Cliente serviços de estacionamento e adicionais, incluindo, mas não limitado a, guarda do veículo, transporte do veículo para o aeroporto, lavagem e reparos, sob as condições e restrições detalhadas neste documento.</p>
                <br />
                <p><strong>5. CONDIÇÕES COMPLEMENTARES E PREÇO</strong><br /><br />
                    Os procedimentos e preços para os serviços são definidos pelas comunicações entre o Cliente e a Empresa e expostos no website da SkyPark, estando sujeitos aos termos acordados e confirmados por ambas as partes.</p>
                <br />
                <p><strong>6. OBRIGAÇÕES DO CLIENTE</strong><br /><br />
                    O Cliente compromete-se a entregar o veículo em condições legais e operacionais adequadas, autorizar o uso do seu seguro veicular, e a cumprir com os procedimentos de check-in e check-out, bem como a efetuar o pagamento pelos serviços prestados.</p>
                <br />
                <p><strong>7. NÃO CUMPRIMENTO DAS OBRIGAÇÕES DO CLIENTE</strong><br /><br />
                    A inobservância das obrigações por parte do Cliente resultará em penalidades específicas, que incluem, mas não estão limitadas a, multas relacionadas a documentação, seguro, combustível, chaves e possíveis custos com reboque.</p>
                <br />
                <p><strong>8. ENTREGA E RECEPÇÃO DO VEÍCULO</strong><br /><br />
                    A entrega e a recepção do veículo serão acompanhadas por um representante da SkyPark e submetidas a um processo de check-in e check-out, garantindo a proteção tanto do Cliente quanto da Empresa.</p>
                <br />
                <p><strong>9. RESPONSABILIDADE DA EMPRESA</strong><br /><br />
                    A Empresa se responsabiliza por danos diretamente relacionados à gestão do veículo por seus colaboradores, excluindo-se situações onde a responsabilidade é expressamente excluída por este documento.</p>
                <br />
                <p><strong>10. RESPONSABILIDADE DO CLIENTE</strong><br /><br />
                    Multas ou despesas decorrentes de irregularidades com o veículo ou sua documentação são de responsabilidade do Cliente, assim como o não pagamento dos serviços que pode resultar em retenção do veículo até a regularização dos débitos.</p>
            </div>
        </div>
    );
}

