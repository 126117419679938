import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import ArticleCard from '../components/articleCard';

export default function Blog() {

    const [lang, setLang] = useState("pt");
    const [articles, setArticles] = useState<any>(null);

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                let colRef = collection(db, 'skypark/blog/articles');
                let docsSnap = await getDocs(colRef);
                let data: any = [];
                docsSnap.forEach(doc => {
                    const docData:any = { id: doc.id, ...doc.data() };
                    if (docData.draft) return;
                    data.push(docData);
                });
                setArticles(data);
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        fetchData();
    }, []);

    if (!articles) {
        return (
            <div className='relative flex items-center justify-center w-full min-h-screen'>
                <CircularProgress color="inherit" />
            </div>
        )
    }

    return (
        <div className='relative py-[5rem] w-full min-h-screen flex flex-col gap-[5rem] px-[8vw] bg-[#f0f0f0]'>
            <h3 className='text-left font-poppins text-[2rem] text-[var(--primary)]'>{lang === 'pt' ? 'Os nossos artigos' : 'Our articles'}</h3>
            <div className='grid oito:grid-cols-[repeat(auto-fill,18rem)] gap-8'>
                {
                    articles && articles.map((ele: any, index: number) => {
                        return (
                            <ArticleCard key={index} ele={ele} />
                        )
                    })
                }
                <button>
                    <div className='w-full oito:w-[18rem] h-[18rem] font-bold bg-white brightness-90 hover:brightness-75 rounded-[25px] justify-center items-center flex place-self-center'>
                        brevemente, novo artigo
                    </div>
                </button>
            </div>
        </div>
    );
}

