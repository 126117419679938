import React, { useEffect, useState } from 'react';

import Security from '../../../content/icons/security.png';
import Profissionalism from '../../../content/icons/profissionalism.png';
import Valuess from '../../../content/icons/values.png';

export default function Values() {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='relative min-h-screen bg-white px-[8vw] py-[5rem]'>
            <div className='flex flex-col justify-between w-full oito:flex-row oito:items-center'>
                <div className='w-full nove:w-[80%] text-[1.3rem] quatro:text-[1.5rem] seis:text-[2rem] nove:text-[2.5rem]'>
                    <h2 className='font-bold text-balance'>
                        {lang === "pt" ? "SkyPark - Parque de carros mais dedicado ao cliente!" : "SkyPark - Car Park most dedicated to the customer!"}
                    </h2>
                </div>
                <div className='w-full nove:w-[20%] flex nove:justify-end items-center'>
                    <button onClick={() => window.location.href = "#services"} className='w-[12rem] py-2 border-4 font-bold border-[var(--primary)] rounded-[10px] hover:underline'>{lang === 'pt' ? 'VER SERVIÇOS' : 'VIEW SERVICES'}</button>
                </div>
            </div>
            <div className='flex flex-col nove:flex-row justify-center nove:justify-start items-center gap-[10vw] mt-[5rem] px-[5rem] font-poppinsM'>
                <div className='w-full flex flex-col gap-8 min-h-[35rem] min-w-[12rem] max-w-[20rem] nove:max-w-full'>
                    <div className='flex justify-center w-full'>
                        <img src={Security} alt="icon" className='h-[8rem]' />
                    </div>
                    <h3 className='font-poppins text-[1.5rem] seis:text-[2rem]'>{lang === 'pt' ? 'Segurança' : 'Security'}</h3>
                    <div className='w-[5rem] bg-[var(--primary)] h-[.2rem]'></div>
                    <p className='text-[1.2rem]'>
                        {lang === "pt" ? "As nossas instalações são compostas por Parque Coberto e Parque Descoberto, totalmente cercados e monitorados." : "Our facilities are made up of Covered Park and Uncovered Park, fully fenced and monitored."}
                    </p>
                </div>
                <div className='w-full flex flex-col gap-8 min-h-[35rem] min-w-[12rem] max-w-[20rem] nove:max-w-full'>
                    <div className='flex justify-center w-full'>
                        <img src={Profissionalism} alt="icon" className='h-[8rem]' />
                    </div>
                    <h3 className='font-poppins text-[1.5rem] seis:text-[2rem]'>{lang === 'pt' ? 'Profissionalismo' : 'Professionalism'}</h3>
                    <div className='w-[5rem] bg-[var(--primary)] h-[.2rem]'></div>
                    <p className='text-[1.2rem]'>
                        {lang === "pt" ? "O nosso principal objetivo é fornecer um serviço de excelência a um custo reduzido, no qual os veículos de nossos Clientes são sempre nossa prioridade, com vigilância 24 horas." : "Our main objective is to provide an excellent service at a reduced cost, in which our Customers."}
                    </p>
                </div>
                <div className='w-full flex flex-col gap-8 min-h-[35rem] min-w-[12rem] max-w-[20rem] nove:max-w-full'>
                    <div className='flex justify-center w-full'>
                        <img src={Valuess} alt="icon" className='h-[8rem]' />
                    </div>
                    <h3 className='font-poppins text-[1.5rem] seis:text-[2rem]'>{lang === 'pt' ? 'Valores' : 'Values'}</h3>
                    <div className='w-[5rem] bg-[var(--primary)] h-[.2rem]'></div>
                    <ul className='font-poppins text-[1.5rem] list-disc pl-4'>
                        <li>{lang === 'pt' ? 'Excelência' : 'Excellence'}</li>
                        <li>{lang === 'pt' ? 'Eficiência' : 'Efficiency'}</li>
                        <li>{lang === 'pt' ? 'Rigor' : 'Rigor'}</li>
                        <li>{lang === 'pt' ? 'Segurança' : 'Security'}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

