import React from 'react';
import Logo from '../content/images/logo.png';

export default function Loading() {
    return (
        <div className='absolute top-0 left-0 w-full h-screen bg-[var(--primary)] flex flex-col justify-center items-center z-[99] text-white gap-6'>
            <img src={Logo} alt="logo" className='w-[25rem] brightness-0 invert' />
            <p className='font-bold'>Loading...</p>
        </div>
    );
}

