import React, { useEffect, useState } from "react";

export default function FirstForm({ formData, setFormData, lang }: { formData: any, setFormData: any, lang: string }) {

    // Form lists
    const [isContribuinteYes, setIsContribuinteYes] = useState(false);

    const handleToggle = (event: any) => {
        setFormData({ ...formData, taxName: "", taxNumber: "" });
        setIsContribuinteYes(event.target.value === 'yes');
    };

    const handleChange = (e: any) => {
        let value = e.target.value;
        if (value === "phoneNumber") {
            value = value.replace(/[+ ]/g, "");
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setIsContribuinteYes(formData.taxName ? true : false);
    }, [formData.taxName]);

    return (
        <>
            <div className="flex items-center gap-4">
                <label htmlFor="licensePlate" className="w-1/3">
                    {lang === "pt" ? "Matrícula:" : "License plate:"}
                </label>
                <p className='uppercase'>{formData.licensePlate}</p>
            </div>
            <div className="flex items-center gap-4">
                <label htmlFor="marca" className="w-1/3">
                    {lang === "pt" ? "Marca:" : "Brand:"}
                </label>
                <input
                    type="text"
                    id="brand"
                    name="brand"
                    placeholder='|'
                    value={formData.brand}
                    className="capitalize w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />

            </div>
            <div className="flex items-center gap-4">
                <label htmlFor="modelo" className="w-1/3">
                    {lang === "pt" ? "Modelo:" : "Model:"}
                </label>
                <input
                    type="text"
                    id="model"
                    name="model"
                    placeholder='|'
                    value={formData.model}
                    className="capitalize w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />

            </div>
            <div className="flex items-center gap-4">
                <label htmlFor="cor" className="w-1/3">
                    {lang === "pt" ? "Cor:" : "Color:"}
                </label>
                <input
                    type="text"
                    id="color"
                    name="color"
                    placeholder='|'
                    value={formData.color}
                    className="capitalize w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />

            </div>
            <div className="flex items-center gap-4">
                <label htmlFor="nome" className="w-1/3">
                    {lang === "pt" ? "Nome:" : "Name:"}
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder='|'
                    value={formData.name}
                    className="capitalize w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />

            </div>
            <div className="flex items-center gap-4">
                <label htmlFor="apelido" className="w-1/3">
                    {lang === "pt" ? "Apelido:" : "Last name:"}
                </label>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder='|'
                    value={formData.lastName}
                    className="capitalize w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />
            </div>
            <div className="flex items-center gap-4">
                <label htmlFor="telemovel" className="w-1/3">
                    {lang === "pt" ? "Telemóvel:" : "Phone number:"}
                </label>
                <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder='|'
                    value={formData.phoneNumber}
                    className="capitalize w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />

            </div>

            <div className="flex items-center gap-4">
                <label htmlFor="e-mail" className="w-1/3">
                    E-mail:
                </label>
                <input
                    type="email"
                    id="email"
                    name="email" // Changed from e-mail to email
                    placeholder='|'
                    value={formData.email}
                    className="w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />

            </div>

            <div className="flex items-center gap-4">
                <label htmlFor="e-mail" className="w-1/3">
                    {lang === "pt" ? "Fatura com contribuinte?" : "Invoice with Taxpayer?"}
                </label>
                <div className='w-1/3'>
                    <input
                        type="radio"
                        id="contribuinteYes"
                        name="contribuinte"
                        value="yes"
                        checked={isContribuinteYes}
                        onChange={handleToggle}
                        className='w-[20px] h-[20px] accent-[var(--primary)]'
                    />
                    <label htmlFor="contribuinteYes" className='ml-4'>{lang === "pt" ? "Sim" : "Yes"}</label>
                </div>
                <div className='w-1/3'>
                    <input
                        type="radio"
                        id="contribuinteNo"
                        name="contribuinte"
                        value="no"
                        checked={!isContribuinteYes}
                        onChange={handleToggle}
                        className='w-[20px] h-[20px] accent-[var(--primary)]'
                    />
                    <label htmlFor="contribuinteNo" className='ml-4'>{lang === "pt" ? "Não" : "No"}</label>
                </div>

            </div>
            {isContribuinteYes &&
                <>
                    <div className="flex items-center gap-4">
                        <label htmlFor="nome" className="w-1/3">
                            {lang === "pt" ? "Nome para fatura:" : "Name for invoice:"}
                        </label>
                        <input
                            type="text"
                            name="taxName"
                            placeholder='|'
                            value={formData.taxName}
                            className="w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex items-center gap-4">
                        <label htmlFor="taxNumber" className="w-1/3">
                            {lang === "pt" ? "Número de contribuinte:" : "Taxpayer number:"}
                        </label>
                        <input
                            type="number"
                            name="taxNumber"
                            placeholder='|'
                            value={formData.taxNumber}
                            className="w-2/3 form-input bg-[transparent] px-6 py-2 border-2 rounded-full border-black"
                            onChange={handleChange}
                        />
                    </div>
                </>
            }
        </>
    );
}

