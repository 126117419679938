import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Logo from '../content/images/logo.png';
import Dialog from '../components/alertDialog';

export default function Login() {

    const navigate = useNavigate();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user:any) => {
			if (user)
				navigate('/admin');
		});
		return () => unsubscribe();
	}, [navigate]);


    async function login(e:any) {
        e.preventDefault();
        if (username === '' || password === '') {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        await signInWithEmailAndPassword(auth, `${username.toLowerCase()}@multipark.pt`, password);
    }

    return (
        <div className='flex flex-col items-center pt-20 w-full min-h-screen bg-[#f0f0f0]'>
            <img src={Logo} alt="logo" className='w-[10rem] cinco:w-[14rem] py-10' />
            <form action="submit" onSubmit={login} className='flex flex-col gap-4'>
                <h1 className='text-center font-poppins'>Admin Login</h1>
                <input
                    type="text"
                    placeholder='Username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className='px-4 py-2 lowercase rounded-full'
                />
                <input
                    type="password"
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='px-4 py-2 rounded-full'
                />
                <button
                    type='submit'
                    className='bg-[#333] text-white px-4 py-2 rounded-full hover:bg-[var(--primary)] transition-all duration-300'
                >
                    Login
                </button>
            </form>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </div>
    );
}

