import React from 'react';
import Header from './components/header';
import Values from './components/values';
import Services from './components/services';
import Extras from './components/extras';
import Data from './components/data';

export default function Website({texts}:any) {
    return (
        <div className='font-poppins'>
            <Header />
            <Values />
            <Services texts={texts}/>
            <Extras texts={texts}/>
            <Data/>
        </div>
    );
}

