import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHouse } from "react-icons/fa6";

export default function Navbar() {
    const [lang, setLang] = useState("pt");
    const [active, setActive] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    useEffect(() => {
        setActive(false);
    }, [location]);
    
    const changeLanguage = (lang: string) => {
        localStorage.setItem("lang", lang);
        setLang(lang);
        window.location.reload();
    };

    function isActive(href: string) {
        if (href === window.location.pathname)
            return "text-[var(--primary)]";
        return "";
    }

    return (
        <div className={`absolute w-full top-0 left-0 h-[4rem] z-[10]`}>
            <div className='w-full h-full px-[5vw] flex justify-between items-center z-[2] bg-white'>
                <Link className={`${isActive("/")} flex gap-2 items-center relative font-bold hover:text-[var(--primary)] transition-all duration-300 text-[1.2rem] font-poppins`} to="/"><FaHouse size={15} className='hidden oito:flex' />SKY PARK</Link>
                <div className={`gap-8 hidden oito:flex uppercase`}>
                    <Link className={`${isActive("/about")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/about">{lang === "pt" ? "Sobre" : "About"}</Link>
                    <Link className={`${isActive("/services")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/services">{lang === "pt" ? "Serviços" : "Services"}</Link>
                    <Link className={`${isActive("/blog")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/blog">Blog</Link>
                    <Link className={`${isActive("/contact")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/contact">{lang === "pt" ? "Contactar" : "Contact"}</Link>
                    <Link className={`${isActive("/book")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/book">{lang === "pt" ? "Reservar" : "Book"}</Link>
                    <p className='font-bold'><span className={`cursor-pointer ${lang === 'pt' ? 'text-[var(--primary)]' : ''}`} onClick={() => changeLanguage('pt')}>PT</span>&ensp;&ensp;|&ensp;&ensp;<span className={`cursor-pointer ${lang === 'en' ? 'text-[var(--primary)]' : ''}`} onClick={() => changeLanguage('en')}>EN</span></p>
                </div>
                <div onClick={() => setActive(!active)} className='oito:hidden flex flex-col gap-[4px]'>
                    <div className={`w-6 h-[3px] bg-black ${active && 'rotate rotate-45 translate-y-[.35rem]'} transition-all duration-300`}></div>
                    <div className={`w-6 h-[3px] bg-black ${active && 'hidden'}`}></div>
                    <div className={`w-6 h-[3px] bg-black ${active && 'rotate -rotate-45 translate-y-[-.10rem]'} transition-all duration-300`}></div>
                </div>
            </div>
            {active &&
                <div id='mobile-navbar' className='flex flex-col gap-8 bg-white px-[5vw] items-end py-4 shadow-lg translate-y-[-125%] z-[1]'>
                    <Link className={`${isActive("/about")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/about">{lang === "pt" ? "Sobre" : "About"}</Link>
                    <Link className={`${isActive("/services")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/services">{lang === "pt" ? "Serviços" : "Services"}</Link>
                    <Link className={`${isActive("/blog")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/blog">Blog</Link>
                    <Link className={`${isActive("/contact")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/contact">{lang === "pt" ? "Contactar" : "Contact"}</Link>
                    <Link className={`${isActive("/book")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/book">{lang === "pt" ? "Reservar" : "Book"}</Link>
                </div>
            }
        </div>
    );
}

/* Styles when menu is active */
// #menu-btn.active .menu-btn-bar:nth-child(1) {
//     transform: rotate(45deg) translate(0.35rem, 0.35rem);
// }

// #menu-btn.active .menu-btn-bar:nth-child(2) {
//     opacity: 0;
// }

// #menu-btn.active .menu-btn-bar:nth-child(3) {
//     transform: rotate(-45deg) translate(0.35rem, -0.35rem);
// }

